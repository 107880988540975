<template>
    <div>
        <div class="mb-base grid grid-cols-1 gap-12">
            <div v-for="item in items">
                <div class="grid grid-cols-1 gap-4">
                    <div>
                        <span class="font-semibold">{{ getItemName(item.item_code) }}</span>
                    </div>
                    <div class="px-4" v-for="mtype in item.mtype">
                        <div class="mb-2">
                            <span>{{ getMtypeName(item.item_code, mtype.mtype_code) }}</span>
                        </div>
                        <!-- 特規 純音聽力圖 start -->
                        <div class="mb-2 grid grid-cols-1 gap-2" v-if="mtype.mtype_id == 36">
                            <div class="grid grid-cols-7 place-items-center gap-2">
                                <div class="col-span-1"></div>
                                <div class="col-span-3 text-center" v-for="row in mtype.value_table_map.rows">
                                    {{ row }}
                                </div>
                            </div>
                            <div class="grid grid-cols-7 place-items-center" v-for="(col, index) in mtype.value_table_map.cols">
                                <div class="col-span-1">
                                    {{ col }}
                                </div>
                                <div class="col-span-3 text-center" v-for="(row, index2) in mtype.value_table_map.rows">
                                    <vs-input class="rounded-none" type="text" style="width: 100px" v-model="form['value_table'][index2][index]" v-if="form.value_table"></vs-input>
                                </div>
                            </div>
                            <!-- <div class="my-8">
                                <canvas :width="imgWidth + 'px'" ref="lineChart"></canvas>
                            </div> -->
                            <!-- <div class="mb-8">
                                <img :width="imgWidth + 'px'" src="@/assets/images/pages/audiogram.jpg" />
                            </div> -->
                        </div>
                        <!-- 特規 純音聽力圖 end -->
                        <!-- 數字輸入框 -->
                        <div v-if="mtype.form_type == 'number'">
                            <vs-input class="w-auto" type="number" v-model.number="form[mtype.mtype_code]"></vs-input>
                        </div>
                        <!-- 單選 -->
                        <div class="flex flex-wrap space-x-4" v-if="mtype.form_type == 'radio' && Object.keys(mtype.value_map).length > 1">
                            <vs-radio v-for="(selection, index) in mtype.value_map" v-model.number="form[mtype.mtype_code]" :vs-name="mtype.mtype_code" :vs-value="index">{{ selection }}</vs-radio>
                        </div>
                        <!-- 多選 -->
                        <div class="flex flex-wrap space-x-4" v-if="mtype.form_type == 'checkbox'">
                            <vs-checkbox v-for="(selection, index) in mtype.value_map" v-model="form[mtype.mtype_code]" :vs-name="mtype.mtype_code" :vs-value="index">{{ selection }}</vs-checkbox>
                        </div>
                        <!-- 圖檔 -->
                        <div class="my-4 grid grid-cols-1 gap-2" v-if="data[item.item_code] && data[item.item_code][mtype.mtype_code]">
                            <div class="flex justify-center text-primary cursor-pointer hover:font-semibold" v-if="data[item.item_code][mtype.mtype_code].source_url">
                                <feather-icon class="mr-1" icon="LinkIcon" svgClasses="h-4 w-4"></feather-icon>
                                <span @click="$common.openExternalUrl(data[item.item_code][mtype.mtype_code].source_url)">檢視原始資料</span>
                            </div>
                            <div v-if="data[item.item_code][mtype.mtype_code].imagefile && getImgType(mtype.mtype_code) == 'iframe'">
                                <iframe :src="getImgUrl(mtype.mtype_code) + data[item.item_code][mtype.mtype_code].imagefile" frameborder="0" style="overflow: hidden; height: 70vh; width: 100%" height="100vh" width="80%" v-if="data[item.item_code][mtype.mtype_code].imagefile"></iframe>
                            </div>
                            <div v-if="data[item.item_code][mtype.mtype_code].imagefile && getImgType(mtype.mtype_code) == 'img'">
                                <img :src="getImgUrl(mtype.mtype_code) + data[item.item_code][mtype.mtype_code].imagefile" :width="imgWidth + 'px'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 填寫人 -->
        <div class="p-4 sticky border border-solid border-l-0 border-r-0 border-b-0 border-grey flex items-center whitespace-no-wrap space-x-4 bg-white" style="bottom: -20px">
            <span>填寫人：{{ dname }}</span>
            <vs-button class="cd-button-2w rounded" @click="upsertMeasureData">送出</vs-button>
            <vs-button class="cd-button-2w rounded" @click="print" v-if="items.length > 1">列印</vs-button>
        </div>
    </div>
</template>
<script>
import { upsertMeasureData } from '@/api/user'
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: null,
        },
        itemCode: {
            type: String,
            required: false,
            default: '',
        },
        itemList: {
            type: Array,
            required: true,
            default: [],
        },
        event_id: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    inject: ['getItemName', 'getMtypeName'],
    data() {
        return {
            form: [],
            items: [],
            chart: null,
            xAxisData: ['250(頻率)', '0.5K', '1K', '1.5K', '2K', '3K', '4K', '6K', '8K'],
            // xAxisData: ['500K', '1K', '2K', '4K'],
        }
    },
    mounted() {
        const _self = this
        this.items = this.itemCode == '' || this.itemCode == 'name' ? this.itemList : [_.find(this.itemList, { item_code: this.itemCode })]
        _.forEach(this.items, function (item) {
            _.forEach(item.mtype, function (mtype) {
                let defaultValue = null
                if (_self.data[item.item_code] && _self.data[item.item_code][mtype.mtype_code]) {
                    defaultValue = _self.data[item.item_code][mtype.mtype_code].value
                }
                if (mtype.form_type == 'number') _self.$set(_self.form, mtype.mtype_code, defaultValue ? defaultValue : null)
                if (mtype.form_type == 'radio') _self.$set(_self.form, mtype.mtype_code, defaultValue ? parseInt(defaultValue) : parseInt(Object.keys(mtype.value_map)[0]))
                if (mtype.form_type == 'checkbox') _self.$set(_self.form, mtype.mtype_code, defaultValue ? _self.$common.decimalToArray(defaultValue) : [0])

                // 特規 純音聽力圖 不知道是啥 好像是row跟col
                if (mtype.mtype_id == 36) {
                    // const template = [
                    //     ['', '', '', ''],
                    //     ['', '', '', ''],
                    // ]
                    const template = [
                        ['', '', '', '', '', '', '', '', ''],
                        ['', '', '', '', '', '', '', '', ''],
                    ]
                    const defaultValueTable = _self.data[item.item_code] && _self.data[item.item_code][mtype.mtype_code] && _self.data[item.item_code][mtype.mtype_code].value_table ? _self.data[item.item_code][mtype.mtype_code].value_table : template
                    _self.$set(_self.form, 'value_table', defaultValueTable)
                    // const timer = setInterval(function () {
                    //     clearInterval(timer)
                    //     _self.showChart()
                    // }, 2000)
                }
            })
        })
    },
    computed: {
        windowHeight() {
            return window.innerHeight
        },
        imgWidth() {
            const innerWidth = window.innerWidth
            const width = innerWidth > 630 ? 500 : innerWidth - 100
            return width
        },
        dname() {
            return this.$store.getters.name
        },
        print_url() {
            return process.env.VUE_APP_URL + '/freeclinic_print/' + this.event_id + '/' + this.data.pid
        },
        canvas() {
            return this.$refs.lineChart
        },
    },
    methods: {
        // 寫入量測數據
        upsertMeasureData() {
            let payload = { measures: [] }
            const _self = this

            _.forEach(this.items, function (item) {
                _.forEach(item.mtype, function (mtype) {
                    if (_self.form[mtype.mtype_code]) {
                        payload.measures.push({
                            event_id: _self.event_id,
                            pid: _self.data.pid,
                            item_id: item.item_id,
                            mtype_id: mtype.mtype_id,
                            value: Array.isArray(_self.form[mtype.mtype_code]) ? _self.$common.arrayToDecimal(_self.form[mtype.mtype_code]) : _self.form[mtype.mtype_code],
                            value_table: mtype.mtype_id == 36 ? _self.form['value_table'] : null, // 特規 純音聽力圖
                            writer: _self.dname,
                        })
                    }
                })
            })
            upsertMeasureData(payload).then((res) => {
                this.$bus.$emit('upsertMesasureData')
            })
        },
        print() {
            window.open(this.print_url, '_blank')
        },
        showChart() {
            // const canvas = this.$refs.lineChart
            console.log('this.canvas', this.canvas)
            const ctx = this.canvas.getContext('2d')
            console.log('ctx', ctx)
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.xAxisData,
                    padding: 1,
                    datasets: [
                        {
                            label: '左耳',
                            data: this.form['value_table'][0],
                            borderColor: 'blue',
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: '右耳',
                            data: this.form['value_table'][1],
                            borderColor: 'red',
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    reverse: true,
                                    beginAtZero: true,
                                    min: -20,
                                    max: 120,
                                    stepSize: 10,
                                },
                            },
                        ],
                    },
                    plugins: {
                        title: function (tooltipItem) {
                            title = '頻率：' + tooltipItem[0].label
                            return title
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label = data.datasets[tooltipItem.datasetIndex].label || ''
                                if (label) {
                                    label = '分貝:' + tooltipItem.yLabel
                                }
                                return label
                            },
                        },
                    },
                },
            })
        },
        getImgUrl(code) {
            const map = {
                ECG: 'electrocardiogram',
                Fundoscopy_OS: 'funduscopy',
                Fundoscopy_OD: 'funduscopy',
            }
            return map[code] ? process.env.VUE_APP_UPLOAD + '/' + map[code] + '/' : map[code]
        },
        getImgType(code) {
            const map = {
                ECG: 'iframe',
                Fundoscopy_OS: 'img',
                Fundoscopy_OD: 'img',
            }
            return map[code] ? map[code] : ''
        },
    },
}
</script>
